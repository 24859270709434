@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in-left {
  animation: slideInLeft 0.8s ease-out forwards;
}

@keyframes slideInBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slide-in-bottom {
  animation: slideInBottom 0.8s ease-out forwards;
}

@keyframes slideInBottomDelayed {
  0% {
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
  }
  1% {
    visibility: visible;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

.animate-slide-in-bottom-delayed {
  animation: slideInBottomDelayed 0.8s ease-out 0.5s forwards; /* 0.5s delay */
}

/* feedback */

.underline-gradient {
  background-image: theme("colors.underline-gradient");
  background-size: 100% 0.1em;
  background-position: 0 100%;
  background-repeat: no-repeat;
}

/* App.css */
/* App.css */
@keyframes fillBackgroundFromBottomLeft {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 100%;
  }
}

.fill-animation-bottom-left {
  background: linear-gradient(
    to top right,
    rgba(98, 84, 255, 0.7),
    #5b40ff,
    #2400ff,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1)
  );
  background-size: 200% 200%;
  animation: fillBackgroundFromBottomLeft 3s forwards;
}

@keyframes fillBackgroundFromBottomToTopLeft {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

.fill-animation-bottom-top-left {
  background: linear-gradient(
    to top left,

    #01cf60,
    #46df9f,
    #48d7b4,
    #fff,
    #fff,
    #fff
  );
  background-size: 200% 200%;
  background-position: 0% 100%; /* Initial state at the bottom */
  animation: fillBackgroundFromBottomToTopLeft 3s forwards;
}

@keyframes fillBackgroundFromBottomToTop {
  0% {
    background-position: -20% 0%;
  }
  100% {
    background-position: 20% 100%;
  }
}

.fill-animation-bottom-top {
  background: linear-gradient(
    to top,

    #ff5c00,
    #ff9840,
    #ffa354,
    #fff,
    #fff,
    #fff
  );
  background-size: 200% 200%;
  background-position: 0% 100%; /* Initial state at the bottom */
  animation: fillBackgroundFromBottomToTop 3s forwards;
}

@keyframes textColorChange {
  0% {
    color: #636466;
  }
  100% {
    color: white;
  }
}

.text-white-animation {
  animation: textColorChange 3s forwards;
}

.spin-animation {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* hearth */

.animation-wrapper {
  position: relative;
  overflow: hidden;
}

.heart-animation-container {
  position: absolute;
  z-index: 10;
  bottom: -100px;
  right: -17px;
  display: block;
}

.heart-animation {
  width: 50px;
  height: 50px;
}

.slideIn-animation {
  display: block;
  animation: slideIn 2s forwards;
}

.slideLeft-animation {
  display: block;
  animation: slideLeft 2s forwards;
}

.shake-animation {
  display: block;
  animation: shake 1s forwards;
}

@keyframes slideIn {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: -12px;
  }
}

@keyframes slideLeft {
  0% {
    bottom: -12px;
    opacity: 1;
  }
  100% {
    bottom: 100px;
    opacity: 0;
  }
}

@keyframes shake {
  0% {
    transform: rotate(10deg);
    bottom: -12px;
    right: -17px;
  }
  25% {
    transform: rotate(-10deg);
    bottom: -12px;
    right: -17px;
  }
  50% {
    transform: rotate(10deg);
    bottom: -12px;
    right: -17px;
  }
  75% {
    transform: rotate(-10deg);
    bottom: -12px;
    right: -17px;
  }
  100% {
    transform: rotate(0deg);
    bottom: -12px;
    right: -17px;
  }
}
