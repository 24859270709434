@keyframes starts {
  0% {
    width: 0px;
    height: 0px;
  }
  100% {
    width: 1500px;
    height: 1500px;
  }
}

@keyframes outs {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes textStart {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes textEnd {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: 0;
  }
}

@keyframes shake {
  0%,
  100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
}

.text-ani {
  z-index: 20;
  margin-bottom: 10px;
  animation: textStart 2s ease-in forwards, textEnd 2s ease-in-out 3.5s forwards;
}

.shaking {
  display: inline-block;
  animation: shake 1s ease-in-out 2s forwards;
}

.start {
  position: absolute;
  bottom: -120px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: starts 1.5s ease-in forwards, outs 2s ease-in-out 4s forwards;
}

.start img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .start,
  .text-ani,
  .shaking {
    display: none; /* Hide on larger screens */
  }
}
