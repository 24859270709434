.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox-container input[type="checkbox"] {
  display: none;
}

.checkbox-container label {
  width: 14px;
  height: 14px;
  border: 1px solid #cecfcd;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.checkbox-container label::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
}

.checkbox-container input[type="checkbox"]:checked + label {
  background-color: #00d97d;
  border-color: #00d97d;
}

.checkbox-container input[type="checkbox"]:checked + label::after {
  content: "✓";
  color: white;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
