.card {
  width: 280px;

  overflow-y: auto;

  position: relative;

  z-index: 1;

  overflow-x: hidden;

  background-color: white;

  display: -webkit-box;

  display: flex;

  -webkit-transition: 0.3s;

  transition: 0.3s;

  -webkit-box-orient: vertical;

  -webkit-box-direction: normal;

  flex-direction: column;

  border-radius: 10px;

  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
}

.card[data-state="#about"] {
  height: 450px;
}

.card[data-state="#about"] .card-main {
  padding-top: 0;
}

.card[data-state="#contact"] {
  height: 430px;
}

.card[data-state="#experience"] {
  height: 550px;
}

.card.is-active .card-avatar {
  -webkit-transform: none;

  transform: none;

  left: 20px;

  width: 50px;

  height: 50px;

  bottom: 10px;
}

.card-header {
  position: relative;

  display: -webkit-box;

  display: flex;

  height: 200px;

  flex-shrink: 0;

  width: 100%;

  -webkit-transition: 0.3s;

  transition: 0.3s;
}

.card-header * {
  -webkit-transition: 0.3s;

  transition: 0.3s;
}

.card-cover {
  width: 100%;

  height: 100%;

  position: absolute;

  height: 160px;

  top: -20%;

  left: 0;

  will-change: top;

  background-size: cover;

  background-position: center;

  -webkit-filter: blur(30px);

  filter: blur(30px);

  -webkit-transform: scale(1.2);

  transform: scale(1.2);

  -webkit-transition: 0.5s;

  transition: 0.5s;
}

.card-avatar {
  width: 100px;

  height: 100px;

  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);

  border-radius: 50%;

  -o-object-position: center;

  object-position: center;

  -o-object-fit: cover;

  object-fit: cover;

  position: absolute;

  bottom: 0;

  left: 50%;

  -webkit-transform: translateX(-50%) translateY(-64px);

  transform: translateX(-50%) translateY(-64px);
}

.card-main {
  position: relative;

  -webkit-box-flex: 1;

  flex: 1;

  display: -webkit-box;

  display: flex;

  padding-top: 10px;

  -webkit-box-orient: vertical;

  -webkit-box-direction: normal;

  flex-direction: column;
}

.card-subtitle {
  font-weight: 700;

  font-size: 13px;

  margin-bottom: 8px;
}

.card-content {
  padding: 20px;
}

.card-desc {
  color: #222;

  font-size: 14px;

  margin: 0;

  font-weight: 400;
}

.card-social {
  display: -webkit-box;

  display: flex;

  -webkit-box-align: center;

  align-items: center;

  padding: 0 20px;
}

.card-social svg {
  fill: #a5b5ce;

  width: 16px;

  display: block;

  -webkit-transition: 0.3s;

  transition: 0.3s;
}

.card-social div {
  color: #222;

  height: 32px;

  width: 32px;

  border-radius: 50%;

  display: -webkit-inline-box;

  display: inline-flex;

  -webkit-box-align: center;

  align-items: center;

  -webkit-box-pack: center;

  justify-content: center;

  -webkit-transition: 0.3s;

  transition: 0.3s;

  background-color: rgba(93, 133, 193, 0.05);

  border-radius: 50%;

  margin-right: 10px;
}

.card-social div:hover svg {
  fill: #324f72;
}

.card-social div:last-child {
  margin-right: 0;
}

.card-buttons {
  display: -webkit-box;

  display: flex;

  background-color: #fff;

  margin-top: auto;

  position: -webkit-sticky;

  position: sticky;

  bottom: 0;

  left: 0;
}

.card-buttons button {
  -webkit-box-flex: 1;

  flex: 1 1 auto;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;

  background: 0;

  font-size: 13px;

  border: 0;

  padding: 15px 5px;

  cursor: pointer;

  color: #222;

  -webkit-transition: 0.3s;

  transition: 0.3s;

  font-weight: 500;

  outline: 0;
}

.card-section {
  display: none;
}

.card-section.is-active {
  display: block;

  -webkit-animation: fadeIn 0.6s both;

  animation: fadeIn 0.6s both;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;

    -webkit-transform: translatey(40px);

    transform: translatey(40px);
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;

    -webkit-transform: translatey(40px);

    transform: translatey(40px);
  }

  100% {
    opacity: 1;
  }
}

.card-timeline {
  margin-top: 30px;

  position: relative;
}

.card-timeline:after {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(134, 214, 243, 0)),
    to(#516acc)
  );

  background: linear-gradient(to top, rgba(134, 214, 243, 0) 0%, #516acc 100%);

  content: "";

  left: 42px;

  width: 2px;

  top: 0;

  height: 100%;

  position: absolute;

  content: "";
}

.card-item {
  position: relative;

  padding-left: 60px;

  padding-right: 20px;

  padding-bottom: 30px;

  z-index: 1;
}

.card-item:last-child {
  padding-bottom: 5px;
}

.card-item:after {
  content: attr(data-year);

  width: 10px;

  position: absolute;

  top: 0;

  left: 37px;

  width: 8px;

  height: 8px;

  line-height: 0.6;

  border: 2px solid #fff;

  font-size: 11px;

  text-indent: -35px;

  border-radius: 50%;

  color: rgba(134, 134, 134, 0.7);

  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#a0aee3),
    to(#516acc)
  );

  background: linear-gradient(to bottom, #a0aee3 0%, #516acc 100%);
}

.card-item-title {
  font-weight: 500;

  font-size: 14px;

  margin-bottom: 5px;
}

.card-item-desc {
  font-size: 13px;

  color: #222;

  line-height: 1.5;
}
