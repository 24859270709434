@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback");

body {
  background-color: rgb(245, 246, 246);
  font-family: "GIP-Regular";
}

@font-face {
  font-family: "GIP-Regular";
  src: url("./fonts/GIP-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GIP-Bold";
  src: url("./fonts/GIP-ExtraBoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GIP-Italic";
  src: url("./fonts/GIP-RegularItalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

.birthday-container {
  width: 50%;
}

.birthday-label {
  font-size: 12px;
  color: #293951;
  font-weight: 600;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.input-icon {
  position: absolute;
  top: 8px;
  left: 8px;
  color: #293951;
  opacity: 0.7;
  font-size: 16px;
}

.birthday-input {
  width: 100%;
  padding: 6px 0px 6px 30px;
  border: 1px solid #cecfd3;
  border-radius: 8px;
  font-size: 12px;
  text-align: start;
  background: none;
  box-sizing: border-box;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue color */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
